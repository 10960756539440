import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ParcelComponent from 'single-spa-react/parcel';
import { mountRootParcel } from 'single-spa';
import ErrorBoundary from 'helpers/ErrorBoundary';
import AppBar from 'components/AppBar/AppBar';
import AppLoader from 'components/AppLoader';
import { Auth } from 'aws-amplify';
import { withTranslation } from 'react-i18next';
import UserContext from 'context/User';
import userRoles from 'enums';

function App({ i18n, setSelectedVendor }) {
  const { userDetails, userRole, selectedVendor, isSettingsLoading } = useContext(UserContext);

  // eslint-disable-next-line no-undef
  const remoteImport = async url => (await SystemJS.import(url)).default;
  const { language } = i18n;

  let id;

  if (userRole === userRoles.VENDOR && selectedVendor) {
    const { vendorId } = selectedVendor;
    id = vendorId;
  }

  return (
    <div className="app-container main-container">
      {isSettingsLoading && <AppLoader />}
      <AppBar userDetails={userDetails} selectVendor={setSelectedVendor} selectedVendor={selectedVendor} />
      {(userRole === userRoles.VENDOR && id) || userRole !== userRoles.VENDOR ? (
        <ErrorBoundary>
          <Router>
            <Route
              path="/suite/dashboard"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/dashboard')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/costing"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/costing')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/contact"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/contact')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/trainingmaterial"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/training')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  container="internal"
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/manualslotting"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/manualslotting')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/brokeroppt"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/brokeroppt')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/item-management"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/item-management')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/autoslotting"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/autoslotting')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/paperlot"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/paperlot')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/hierarchy"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/hierarchy')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/sustainability"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/sustainability')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/supplier-climate-commitment"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/supplier-climate-commitment')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/vbt"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/vbt')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/diversity-equity-inclusion"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/diversity-equity-inclusion')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />

            <Route
              path="/suite/brandconversion"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/brandconversion')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
            <Route
              path="/suite/assortmentclassification"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/assortmentclassification')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />

            <Route
              path="/suite/scsupport"
              render={() => (
                <ParcelComponent
                  config={remoteImport('@portal/scsupport')}
                  mountParcel={mountRootParcel}
                  Auth={Auth}
                  wrapWith="section"
                  vendorId={id}
                  language={language}
                  className="app-container"
                />
              )}
            />
          </Router>
        </ErrorBoundary>
      ) : null}
    </div>
  );
}

export default withTranslation()(App);
