import React from 'react';
import { Auth } from 'aws-amplify';
import UserContext from 'context/User';
import RolesMapper from 'helpers/RolesMapper';
import { withTranslation } from 'react-i18next';
import { setAlreadyReadBanners } from '../../util/helpers';
import { isMobile } from 'react-device-detect';

const fnb_report = process.env.REACT_APP_FNB_REPORT;
const pfas_report = process.env.REACT_APP_PFAS_REPORT;

class UserWidget extends React.Component {
  static contextType = UserContext;

  state = {
    visible: false
  };

  toggleMenu = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  changeLanguage = lng => {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  };

  refreshCurrentPage = () => {
    setAlreadyReadBanners('banners', '');
    window.location.reload();
  }

  logOut = async () => {
    const { selectVendor } = this.props;
    selectVendor({ vendorId: null, vendorName: null });
    await Auth.signOut();
    // FIXME: when find out how to unmount a parcel
    window.location.reload();
    // Clear specific keys from local storage
    localStorage.removeItem('selectedAttributes_brandMaintenance');
    localStorage.removeItem('selectedAttributes_itemView');
  };

  render() {
    const { visible } = this.state;
    const { userDetails, userRole } = this.context;
    const { t } = this.props;

    const supplierNamePositon = {
      fontSize: '23px',
      textTransform: 'uppercase',
      fontFamily: 'myriad-pro, sans-serif',
      fontWeight: '300',
      borderRight: '1px dotted #AAA',
      paddingRight: '36px',
      boxSizing: 'border-box',
      color: '#888',
      height: '32px'
    };

    const supplierNamePositonMobile = {
      ...supplierNamePositon,
      position: 'fixed', left: '24px', fontSize: '14px', borderRight: 'none', lineHeight: '28px'
    };

    let displayRole = t(RolesMapper(userRole)).toUpperCase();
    displayRole = displayRole === 'ASSOCIATE' ? 'COLLEAGUE' : displayRole;

    return (
      <React.Fragment>
        <div style={isMobile ? supplierNamePositonMobile : supplierNamePositon}>{userDetails.supplierName}</div>
        <div className={visible ? 'user-widget show' : 'user-widget'}>
          <div className="user">
            <div className="name">
              {userDetails.name ? <span className="welcome">{`${t('landing.welcome')} `}</span> : null}
              {userDetails.name ? userDetails.name.toUpperCase() : null}
            </div>
            {userRole ? <div className="type">{displayRole}</div> : null}
          </div>
          <div
            className="user-pic hover-brighten"
            role="button"
            tabIndex="0"
            onKeyPress={this.toggleMenu}
            onClick={this.toggleMenu}
          >
            <div className="pic"></div>
            <span className="menuicon fi flaticon-caret-down " />
          </div>
          {visible && (
            <div
              className="account-menu show"
              role="button"
              tabIndex="-1"
              onClick={this.toggleMenu}
            >
              <ul>
                <li className="hide">
                  <span className="icon fi flaticon-user" />
                  {t('landing.profile')}
                </li>
                <li className="hide">
                  <span className="icon fi flaticon-gear" />
                  {t('landing.settings')}
                </li>
                <li>
                  <span className="icon fi flaticon-subject" />
                  <div>
                    <div>{t('landing.changeLanguage')}</div>
                    <div className="sub-text">{t('landing.setLanguage')}</div>
                  </div>
                  <div className="submenu">
                    <ul>
                      <li tabIndex="-2" onClick={() => this.changeLanguage('en')}>
                        <span className="linkbutton">English</span>
                      </li>
                      <li tabIndex="-3" onClick={() => this.changeLanguage('fr')}>
                        <span className="linkbutton">French</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <span className="icon fi flaticon-question-1" />
                  <div>
                    <div>{t('landing.help')}</div>
                  </div>
                  <div className="submenu">
                    <ul>
                      <li tabIndex="-3" onClick={() => window.open('https://view.vzaar.com/21470750/video', '_blank')}>
                        <span className="linkbutton">Create and Modify Contacts</span>
                      </li>
                      <li tabIndex="-4" onClick={() => window.open('https://view.vzaar.com/21470748/video', '_blank')}>
                        <span className="linkbutton">Link Unlink contacts</span>
                      </li>
                    </ul>
                  </div>
                </li>
                {displayRole === 'COLLEAGUE' && <li>
                  <span className="icon fi flaticon-download" />
                  <div>
                    <div>{t('landing.reports')}</div>
                  </div>
                  <div className="submenu">
                    <ul>
                      <li tabIndex="-3" onClick={() => window.open(fnb_report, '_blank')}>
                        <span className="linkbutton">Fnb Auto Approved Report</span>
                      </li>
                      <li tabIndex="-3" onClick={() => window.open(pfas_report, '_blank')}>
                        <span className="linkbutton">PFAS Daily Report</span>
                      </li>
                    </ul>
                  </div>
                </li>}
                <li onClick={() => this.refreshCurrentPage()}>
                  <span className="icon fi flaticon-loading" />
                  {t('landing.notifications')}
                </li>
                <li onClick={this.logOut}>
                  <span className="icon fi flaticon-logout" />
                  {t('landing.logOut')}
                </li>
              </ul>
            </div>
          )}
          {visible && (
            <div
              role="button"
              tabIndex="-4"
              className="backgroundClickContainer"
              onClick={this.toggleMenu}
            />
          )}
        </div>
        <div role="button" tabIndex="-4" className="sidemenu-toggle" onClick={this.toggleMenu}>
          <i className="fi flaticon-menu"></i>
        </div>
        <div
          role="button"
          tabIndex="-5"
          className={visible ? 'sidemenu-closer show' : 'sidemenu-closer'}
          onClick={this.toggleMenu}
        >
          <i className="fi flaticon-close"></i>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(UserWidget);
